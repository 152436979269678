import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDescription, setStepNumber, setTitle} from "../../../../../../store/slices/verification";
import FaceDetectIcon from "../../../../../../images/svg/face_id_light.svg"
import ButtonContained from "../../../../../buttons/buttonContained";
import {navigate} from "gatsby";
import {getLang} from "../../../../../../store/slices/lang";
import sumsubApiRequest from "../../../../../verificate/sumsubApiRequest";
import {useEffect} from "react";

const InitStep = () =>{
  const lang = useSelector(getLang);
  const QueryString = window.location.search
  const urlParams = new URLSearchParams(QueryString);

  if (urlParams.has("applicantId")){
    const applicantId = urlParams.get('applicantId')
    localStorage.setItem("applicantId", applicantId)
  }else{
    navigate(`/${lang}/`);
  }

  const dispatch = useDispatch()
  dispatch(setTitle("Verification"))
  dispatch(setDescription("To activate your investment account, let's verify your identity."))
  const stepNumber = useSelector(state => state.verification.stepNumber)

  const nextStep = () =>{
    dispatch(setStepNumber(stepNumber+1))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  //receiving user SID and Country by applicantId
  useEffect(() => {
    sumsubApiRequest('get', `/resources/applicants/${localStorage.getItem('applicantId')}/one`).then(response=>{
      localStorage.setItem('SID', response.data.externalUserId)
      if (response.data.info?.country){
        localStorage.setItem('country', response.data.info?.country)
      }else{
        localStorage.setItem('country', response.data.fixedInfo?.country)
      }
    })
  }, []);
  return(
    <div>
      <FaceDetectIcon style={{margin: "60px auto 46px", width: "100%"}}/>
      <ButtonContained style={{width: "100%"}} text={"Pass verification"} onClickFunction={nextStep}/>
    </div>
  )
}

export default InitStep