import * as React from "react";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  buttonTextWithIcon: {
    display: "flex", gap: 5, alignItems: "center"
  }
}))

const TextButton = ({text, icon, onClickFunction, className}) => {
  const classes = useStyle()
  className = className ?? "";
  const generalClass = [classes.buttonTextWithIcon, className].join(" ");

  return (
    <button onClick={onClickFunction}
            className={generalClass}>
      {icon}
      <span style={{color: "#1A21D3", opacity: 0.75}}>
        {text}
      </span>
    </button>
  )

}

export default TextButton