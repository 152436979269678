import * as React from "react";
import useStylesForm from "../../styles";
import StatusIcon from "../../../../../../images/svg/status-icon.svg"
import StatusOk from "../../../../../../images/svg/status-ok.svg"
import StatusPending from "../../../../../../images/svg/statis-pending.svg"
import {useEffect} from "react";

const Status = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  const classes = useStylesForm();

  return (
    <div className={classes.form}>
      <div className={classes.formTitle}>
        Status
      </div>
      <div className={classes.formTitleScreen}>
        <StatusIcon/>
        <div style={{textAlign: "center"}}>
          The system is already checking your data. The status will change automatically after the verification is completed.
        </div>
      </div>
      <div className={classes.list} style={{marginTop: 46}}>
        <Parameter type={"pending"} name={"Passport"}/>
        <Parameter type={"ok"} name={"Selfie"}/>
        <Parameter type={"pending"} name={"Address confirm"}/>
      </div>
    </div>)
}

const Parameter = ({type, name}) =>{
  const classes = useStylesForm();

  return(
    <div className={classes.listItem}>
      <div>
        {type === "ok" ? <StatusOk/> : <StatusPending/> }
      </div>
      <div>
        {name}
      </div>
    </div>
  )

}

export default Status