import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setDescription,
  setIsVerificationStart,
  setStepNumber,
  setTitle
} from "../../../../../../store/slices/verification";
import SelfieImage from "../../../../../../images/svg/selfie_image.svg"
import ButtonOutlined from "../../../../../buttons/buttonOutlined";
import ButtonContained from "../../../../../buttons/buttonContained";
import useStylesForm from "../../styles";
import {useEffect} from "react";

const VerificationInfo = () =>{

  const dispatch = useDispatch();
  const classes = useStylesForm()
  const stepNumber = useSelector(state => state.verification.stepNumber)
  dispatch(setTitle("Selfie"))
  dispatch(setDescription("Look into the camera. Make a slight rotational movement of the head in a circle — and that's it!"))

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextStep = () =>{
    dispatch(setIsVerificationStart(true))
  }

  const prevStep = () =>{
    dispatch(setStepNumber(stepNumber-1))
  }
  return(
    <div>
      <SelfieImage width={"100%"} height={256} style={{margin: "0 auto"}}/>
      <div className={classes.bottomBar}>
        <ButtonOutlined text={"Back"} style={{width:"100%"}} onClickFunction={prevStep}/>
        <ButtonContained text={"Continue"} style={{width:"100%"}} onClickFunction={nextStep}/>
      </div>
    </div>
  )
}

export default VerificationInfo