import * as React from "react";
import useStylesForm from "../../styles";
import FileUploadIcon from "../../../../../../images/svg/flieUploadIcon.svg"
import {setIdFile} from "../../../../../../store/slices/verification";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

const FileUpload = ({file, setFile})=>{
  const dispatch = useDispatch()

  const classes = useStylesForm()
  // const idFile = useSelector(state=> state.verification.idFile)
  const tipStyles = {display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection:"column"}

  const getUploadedFile = (e)=>{
    if (e.target.files.length>0){
      setFile(e.target.files[0])
    }
  }

  return(
    <div className={classes.fileUpload}>
      <input type={"file"} style={{display: "none"}} id={"file-upload"} onInput={getUploadedFile}/>
      <label for={"file-upload"} style={tipStyles}>
        {
          file ?
              <div className={classes.fileName}>
                {file.name}
              </div>:
              <>
                <FileUploadIcon/>
                <div style={{marginTop: 14}}>
                  Upload file
                </div>
              </>
        }
      </label>
    </div>
  )
}

export default FileUpload