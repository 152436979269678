import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setDescription,
    setIdDocType, setIdFile,
    setStepNumber,
    setTitle
} from "../../../../../../store/slices/verification";
import {Typography} from "@material-ui/core";
import useStylesForm from "../../styles";
import Options from "../formComponents/options";
import ButtonContained from "../../../../../buttons/buttonContained";
import IdCorrect from "../../../../../../images/svg/idCorrect.svg"
import IdCut from "../../../../../../images/svg/idCut.svg"
import IdSmall from "../../../../../../images/svg/idSmall.svg"
import FileUpload from "../formComponents/fileUpload";
import {setExperience} from "../../../../../../store/slices/registration";
import sumsubApiRequest from "../../../../../verificate/sumsubApiRequest";
import {useEffect, useState} from "react";
import Cross from "../../../../../../images/svg/cross-in-circle.svg";
import Tick from "../../../../../../images/svg/success-image.svg";


const IdentificationMethod = () => {
    const dispatch = useDispatch()
    const classes = useStylesForm()
    const idDocument = useSelector(state => state.verification.idDocType)
    const idFile = useSelector(state => state.verification.idFile)
    const stepNumber = useSelector(state => state.verification.stepNumber)
    const [error, setError] = useState("");
    const [isUploaded, setIsUploaded] = useState(false)
    const [showHelper, setShowHelper] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const idOptions = [
        {
            text: "Passport",
            value: "PASSPORT"
        },
        {
            text: "Id-card",
            value: "ID_CARD"
        },]
    dispatch(setTitle("Identification method"))
    dispatch(setDescription(""))
    const validateStep = () => {


        if (idDocument === ""){
            setShowHelper(true)
            window.scrollTo(0, 0);
            setError("Select document type")
            return true
        }

        if (idFile === null){
            setShowHelper(true)
            window.scrollTo(0, 0);
            setError("Upload document")
            return true
        }

        let formData = new FormData()

        formData.append('metadata', JSON.stringify({
            idDocType: idDocument,
            country: localStorage.getItem('country'),
        }));
        formData.append('content', idFile)

        sumsubApiRequest(
            "POST",
            `/uploadDocs/resources/applicants/${localStorage.getItem("applicantId")}/info/idDoc`,
            formData,
            {"Content-Type": "multipart/form-data"})
            .then(r => {
                setIsUploaded(true)
                setError("")
                setShowHelper(true)
                dispatch(setStepNumber(stepNumber+1))
            })
            .catch(e => {
                    setError("Network error")
                    setShowHelper(true)
                }
            )
        return false
    }
    return (
        <div>
            <div className={classes.formItem}>
                <Typography className={classes.title}>
                    Choose identification document
                </Typography>
                <Options name={"id-options"} options={idOptions}
                         onChangeFunction={(value) => dispatch(setIdDocType(value))} stateValue={idDocument}/>
            </div>
            <div className={classes.captionBlock}>
                Take a picture of your passport. The photo should:
                <ul style={{paddingLeft: 20}}>
                    <li>
                        Bright and clean (good quality)
                    </li>
                    <li>
                        Uncut (all corners of the document must be the same)
                    </li>
                </ul>
            </div>
            <div style={{display: "flex", gap: 5}}>
                <IdCorrect/>
                <IdCut/>
                <IdSmall/>
            </div>
            <div>
                <FileUpload setFile={(value)=>dispatch(setIdFile(value))} file={idFile}/>
            </div>
            {
                showHelper && error !== "" ?
                    <div className={classes.helper}>
                        <Cross className={classes.crossInHelper}/>
                        {error}
                    </div>
                    :
                    null
            }
            {
                showHelper && isUploaded ?
                    <div className={classes.success}>
                        <Tick className={classes.crossInHelper}/>
                        Document successfully uploaded
                    </div>
                    :
                    null
            }
            <ButtonContained style={{width: "100%"}} text={"Continue"} onClickFunction={validateStep}/>
        </div>)
}

export default IdentificationMethod