import * as React from "react";
import {Typography} from "@material-ui/core";
import useStylesForm from "../styles";
import {useSelector} from "react-redux";
import InitStep from "./verificationSteps/initStep";
import IdentificationMethod from "./verificationSteps/identificationMethod";
import VerificationInfo from "./verificationSteps/verificationInfo";
import Selfie from "./verificationSteps/selfie";
import VerificationResult from "./verificationSteps/verificationResult";
import AddressConfirm from "./verificationSteps/addressConfirm";
import Status from "./verificationSteps/status";

const VerificationForm = () => {

  const classes = useStylesForm()
  const stepTitle = useSelector(state => state.verification.title)
  const stepDescription = useSelector(state => state.verification.description)
  const stepNumber = useSelector(state => state.verification.stepNumber)
  const showTitleAndDescription = useSelector(state => state.verification.isVerificationStart)
  const verificationStatus = useSelector(state => state.verification.verificationStatus)

  const renderStep = () => {
    switch (stepNumber) {
      case 0:
        return <InitStep/>
      case 1:
        return <IdentificationMethod/>
      case 2:
        if (verificationStatus === "success" || verificationStatus === "failed")
          return <VerificationResult/>
        else
          return <VerificationInfo/>
      case 3:
        return <AddressConfirm/>
      case 4:
        return <Status/>
      default:
        return null
    }
  }
  return (
    <div className={classes.titleStepWrap} style={{maxWidth: "328px", minHeight: "initial", margin: "10vh auto"}}>

      {
        showTitleAndDescription ?
          <div>
            {stepNumber === 2 && <Selfie/>}
            {stepNumber === 4 && <Status/>}
          </div>
          :
          <>
            <div className={classes.form}>
              <Typography className={classes.formPreTitle}>
                Mind-Money.eu
              </Typography>
              <Typography className={classes.formTitle}>
                {stepTitle}
              </Typography>
              <Typography className={classes.formDescription}>
                {stepDescription}
              </Typography>
              <div className={classes.regStepsWrap}>
                {
                  stepNumber > 0 && Array.from({length: 3}).map((_, index) => (
                    <>
                      <svg key={index} width="38" height="6" viewBox="0 0 38 6" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <rect width="38" height="6" rx="3"
                              fill={index <= stepNumber - 1 ? "#1A21D3" : "rgba(122, 126, 232, 0.3)"}/>
                      </svg>
                    </>
                  ))
                }
              </div>
              <div>
                {renderStep()}
              </div>
            </div>
          </>
      }
    </div>
  )
}

export default VerificationForm