import * as React from "react";
import Seo from "../components/seo";
import ClientOnly from "../clientOnly";
import Layout from "../components/layout";
import VerificationForm from "../components/constructor/renderBlock/authentication/forms/verificationForm";

function Verification () {

  return(
    <>
      <Seo
        title={"Mind-money"}
        description={"Verification"}
      />
      <ClientOnly>
        <Layout>
          <VerificationForm/>
        </Layout>
      </ClientOnly>
    </>
  )
}

export default Verification;