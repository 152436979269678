import * as React from "react";
import {useEffect, useRef, useState} from "react";
import useStylesForm from "../../styles";
import {useDispatch} from "react-redux";
import ButtonOutlined from "../../../../../buttons/buttonOutlined";
import ButtonWithIcon from "../../../../../buttons/buttonWithIcon";
import FlipIcon from "../../../../../../images/svg/flip_icon.svg";
import {setIsVerificationStart, setVerificationStatus} from "../../../../../../store/slices/verification";
import ButtonContained from "../../../../../buttons/buttonContained";
import sumsubApiRequest from "../../../../../verificate/sumsubApiRequest";
import Cross from "../../../../../../images/svg/cross-in-circle.svg";
import Tick from "../../../../../../images/svg/success-image.svg";


const Selfie = ()=>{
  const videoComponent = useRef()
  const [mode, setMode] = useState('SELFIE')
  const canvas = useRef();
  const [formData, setFormData] = useState(new FormData)
  const [isUploaded, setIsUploaded] = useState(false)
  const [showHelper, setShowHelper] = useState(false)
  const [error, setError] = useState("")

  const classes = useStylesForm();
  const dispatch = useDispatch()
  const prevScreen = () =>{
    dispatch(setIsVerificationStart(false))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const finishVerification = () =>{

    sumsubApiRequest('POST',
        `/uploadDocs/resources/applicants/${localStorage.getItem("applicantId")}/info/idDoc`,
        formData)
        .then(r=>{
          setShowHelper(true)
          setError("")
          setIsUploaded(true)
          videoComponent.current.srcObject.getTracks().forEach(track => {
            track.stop()
          })
          dispatch(setIsVerificationStart(false))
          dispatch(setVerificationStatus("success"))
        })
        .catch(e=>{
          setShowHelper(true)
          setError("Network error")
        })
  }

  const  capturePhoto = async () => {
    setMode('CHECK')
    if (canvas.current) {
      try {
        const ctx = canvas.current.getContext('2d');
        const videoWidth = videoComponent.current.videoWidth;
        const videoHeight = videoComponent.current.videoHeight;

        const imageWidth = 274 * (videoWidth / videoHeight)
        const imageHeight = 274

        const x = (274 - imageWidth) / 2;
        const y = (274 - imageHeight) / 2;
        ctx.drawImage(videoComponent.current, x, y, imageWidth, imageHeight);

        let selifeForm = new FormData()

        await canvas.current.toBlob((blob) => {
          selifeForm.append('content', blob, 'selfie.png')
          selifeForm.append('metadata', JSON.stringify({
            'idDocType': 'SELFIE',
            'country': localStorage.getItem('country')
          }))

          setFormData(selifeForm)
        })

      } catch (e) {
        console.log(e.message)
      }
    }
  }



  useEffect(() => {
    if (videoComponent.current) {
      navigator.mediaDevices?.getUserMedia({video: { facingMode: 'user' }})
        .then((stream) => {
          videoComponent.current.srcObject = stream
          videoComponent.current.play()
        })
    }
  },[]);

  return(
    <div style={{padding: 27}}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <ButtonOutlined text={"Back"} onClickFunction={prevScreen}/>
        <ButtonWithIcon className={classes.outlinedIconButton} icon={<FlipIcon/>}/>
      </div>
      <div style={{display: "flex", flexDirection: "column", gap: 20, alignItems: "center", marginTop: 27}}>
        <video width="274px" height="274px" ref={videoComponent} autoPlay muted playsInline className={classes.cameraStream}
               style={{visibility: mode === "SELFIE" ? "visible" : "hidden"}}>
        </video>
        <canvas ref={canvas} className={classes.cameraStream} style={{
          visibility: mode === 'CHECK' ? 'visible' : 'hidden',
          position: "absolute",
          objectFit: "cover",
        }} width={274} height={274}>
        </canvas>
        {
          mode === "SELFIE" ?
              <ButtonContained text={"Take a photo"} onClickFunction={()=>capturePhoto()}/>
              :
              <div style={{display: "flex", gap:10}}>
                <ButtonOutlined text={'Retake'} onClickFunction={()=>setMode('SELFIE')}/>
                <ButtonContained text={'Continue'} onClickFunction={()=>finishVerification()}/>
              </div>
        }
      </div>
      {
        showHelper && error !== "" ?
            <div className={classes.helper}>
              <Cross className={classes.crossInHelper}/>
              {error}
            </div>
            :
            null
      }
      {
        showHelper && isUploaded ?
            <div className={classes.success}>
              <Tick className={classes.crossInHelper}/>
              Document successfully uploaded
            </div>
            :
            null
      }
    </div>
  )
}

export default Selfie