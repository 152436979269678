import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setDescription, setIdFile,
  setIsVerificationStart,
  setStepNumber,
  setTitle, setUtilityBill
} from "../../../../../../store/slices/verification";
import FileUpload from "../formComponents/fileUpload";
import ButtonContained from "../../../../../buttons/buttonContained";
import useStylesForm from "../../styles";
import {useEffect, useState} from "react";
import PenIcon from "../../../../../../images/svg/pen-icon.svg"
import TextButton from "../../../../../buttons/buttonTextWithIcon";
import sumsubApiRequest from "../../../../../verificate/sumsubApiRequest";
import Cross from "../../../../../../images/svg/cross-in-circle.svg";
import Tick from "../../../../../../images/svg/success-image.svg";

const AddressConfirm = () => {
  const dispatch = useDispatch()
  const classes = useStylesForm()
  const [showOverview, setShowOverview] = useState(false);
  const utilityBill = useSelector(state=> state.verification.utilityBill)

  const [isUploaded, setIsUploaded] = useState(false)
  const [showHelper, setShowHelper] = useState(false)
  const [error, setError] = useState("")


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitDocument = (e) =>{

    const formData = new FormData ()

    formData.append('metadata', JSON.stringify({
      'idDocType': 'UTILITY_BILL',
      'country': localStorage.getItem('country')
    }))

    if (utilityBill){
      formData.append('content', utilityBill)

      sumsubApiRequest('POST',
          `/uploadDocs/resources/applicants/${localStorage.getItem("applicantId")}/info/idDoc`,
          formData)
          .then(r=>{
            setShowHelper(true)
            window.scrollTo(0, 0);
            setError("")
            setIsUploaded(true)
            setShowOverview(true)
          })
          .catch(e=>{
            setShowHelper(true)
            window.scrollTo(0, 0);
            setError("Network error")
          })
    }
  }

  useEffect(() => {
    if (showOverview) {
      dispatch(setTitle("Overview"))
      dispatch(setDescription("Please, check your details."))
    } else {
      dispatch(setTitle("Address confirm"))
      dispatch(setDescription(""))
    }
  }, [showOverview]);

  return (
    <div>
      {showOverview ?
        <Overview setShowOverview = {setShowOverview}/>
        :
        <>
          <div style={{display: "flex", gap: 5}}>
            <div>
              <div className={classes.listTitle}>
                We <span className={classes.textAccent}>accept</span>:
              </div>
              <ul className={classes.unorderedList}>
                <li>Bank statements</li>
                <li>Utility bills</li>
                <li>Internet bills, cable TV, etc.</li>
                <li>Tax returns</li>
              </ul>
            </div>
            <div>
              <div className={classes.listTitle}>
                We <span className={classes.textAccent}>do not accept</span>:
              </div>
              <ul className={classes.unorderedListRed}>
                <li>Screenshots</li>
                <li>Mobile phone bills</li>
                <li>Medical bills</li>
                <li>Receipts for purchases</li>
              </ul>
            </div>
          </div>
          <FileUpload setFile={(value)=>dispatch(setUtilityBill(value))} file={utilityBill}/>
          <ButtonContained text={"Continue"} style={{width: "100%", marginTop: 46}}
                           onClickFunction={submitDocument}/>
          {
            showHelper && error !== "" ?
                <div className={classes.helper}>
                  <Cross className={classes.crossInHelper}/>
                  {error}
                </div>
                :
                null
          }
          {
            showHelper && isUploaded ?
                <div className={classes.success}>
                  <Tick className={classes.crossInHelper}/>
                  Document successfully uploaded
                </div>
                :
                null
          }
        </>}
    </div>
  )
}

const Overview = ({setShowOverview}) => {
  const classes = useStylesForm()
  const dispatch = useDispatch();

  return (
    <div>
      <div className={classes.title}>Your Id</div>
      <div className={classes.list} style={{marginTop:10}}>
        <div className={classes.listItem}>
          <span>Passport</span> <span>–</span> <TextButton text={"Edit"} icon={<PenIcon/>} onClickFunction={()=>dispatch(setStepNumber(1))}/>
        </div>
        <div className={classes.listItem}>
          <span>Selfie</span> <span>–</span> <span>Accepted</span>
        </div>
        <div className={classes.listItem}>
          <span>Address confirm</span> <span>–</span> <TextButton text={"Edit"} icon={<PenIcon/>} onClickFunction={()=>setShowOverview(false)}/>
        </div>
      </div>
      <ButtonContained text={"Continue"} style={{width: "100%", marginTop: 46}} onClickFunction={()=> {
        dispatch(setIsVerificationStart(true))
        dispatch(setStepNumber(4))}}/>
    </div>
  )
}

export default AddressConfirm
