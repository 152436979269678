import * as React from "react";
import useStylesForm from "../../styles";
import {useDispatch, useSelector} from "react-redux";
import SuccessImage from "../../../../../../images/svg/success-dark.svg"
import FailedImage from "../../../../../../images/svg/failed-dark.svg"
import {setDescription, setStepNumber} from "../../../../../../store/slices/verification";
import ButtonContained from "../../../../../buttons/buttonContained";
import {useEffect} from "react";

const VerificationResult = () => {
  const classes = useStylesForm();
  const dispatch = useDispatch()
  const result = useSelector(state => state.verification.verificationStatus)
  dispatch(setDescription(""))

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goNextStep = ()=>{
    dispatch(setStepNumber(3))
  }

  return (
    <div className={classes.formTitleScreen}>
      {result === "success" ? <>
        <SuccessImage/>
        <div style={{textAlign: "center"}}>
          The facial scan is complete! You can continue.
        </div>
        <ButtonContained text={"Continue"} style={{width: "100%"}} onClickFunction={goNextStep}/>
      </>
        :
        <>
          <FailedImage/>
          <div style={{textAlign: "center"}}>
            The facial scan is complete! You can continue.
          </div>
          <ButtonContained text={"Continue"} style={{width: "100%"}} onClickFunction={goNextStep}/>
        </>
      }
    </div>
  )
}

export default VerificationResult